import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "account-order-detail" }
const _hoisted_2 = { class: "account-order-detail__container" }
const _hoisted_3 = { class: "account-order-detail__list" }
const _hoisted_4 = { class: "account-order-detail__item" }
const _hoisted_5 = {
  key: 0,
  class: "account-order-detail__item-title"
}
const _hoisted_6 = { class: "account-order-detail__item-title-bold" }
const _hoisted_7 = {
  key: 1,
  class: "account-order-detail__item-title"
}
const _hoisted_8 = { class: "account-order-detail__card-list" }
const _hoisted_9 = { class: "account-order-detail__card-item-left" }
const _hoisted_10 = ["onClick", "src"]
const _hoisted_11 = { class: "account-order-detail__card-item-right" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "account-order-detail__card-item-gramm" }
const _hoisted_14 = { class: "account-order-detail__card-item-info" }
const _hoisted_15 = { class: "account-order-detail__card-item-price" }
const _hoisted_16 = { class: "account-order-detail__card-item-count" }
const _hoisted_17 = { class: "info-popup-container__bottom" }
const _hoisted_18 = { class: "info-popup-container__btns" }
const _hoisted_19 = { class: "info-popup-container__btn info-popup-container__btn--black" }
const _hoisted_20 = { class: "info-popup-container__price" }
const _hoisted_21 = {
  key: 0,
  class: "info-popup-container__sale-price"
}
const _hoisted_22 = { class: "info-popup-container__current-price" }
const _hoisted_23 = ["disabled"]
const _hoisted_24 = { key: 0 }
const _hoisted_25 = {
  key: 1,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          (_ctx.shipments.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createTextVNode(" Доставим "),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.moment(_ctx.shipments[0].date.date)), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.properties.length != 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.addressDetail(_ctx.properties)) + " ", 1))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.basket.items, (item, key) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "account-order-detail__card-item",
                key: key
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("img", {
                    onClick: ($event: any) => (_ctx.showDetail(item.productId)),
                    style: {"width":"150px"},
                    class: "account-order-detail__card-item-img",
                    src: 
                    item.images.length > 0
                      ? item.images[0]
                      : require('@/assets/img/no-photo.png')
                  ,
                    alt: ""
                  }, null, 8, _hoisted_10)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", {
                    class: "account-order-detail__card-item-title",
                    onClick: ($event: any) => (_ctx.showDetail(item.productId))
                  }, _toDisplayString(item.name), 9, _hoisted_12),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.displayInfo(item)) + " • " + _toDisplayString(item.basePrice) + " ₽ / кг ", 1),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, _toDisplayString(item.finalPrice) + " ₽ ", 1),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(item.quantity) + " " + _toDisplayString(item.measure), 1)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("button", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            false
              ? (_openBlock(), _createElementBlock("p", _hoisted_21))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.price) + " ₽", 1)
          ])
        ]),
        _createElementVNode("button", {
          class: "info-popup-container__btn info-popup-container__btn--green",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.repeatOrder && _ctx.repeatOrder(...args))),
          disabled: _ctx.loading
        }, [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_24, "Повторить заказ"))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_25))
            : _createCommentVNode("", true)
        ], 8, _hoisted_23)
      ])
    ])
  ]))
}