
import { defineComponent, computed } from "vue";
import TheHeader from "@/layouts/TheHeader.vue";
import TheFooter from "@/layouts/TheFooter.vue";
import CatalogList from "@/components/CatalogList.vue";
import TheBasket from "@/components/TheBasket.vue";
import PickUpLocation from "@/components/PickUpLocation.vue";
import CatalogItemDetail from "@/components/CatalogItemDetail.vue";
import RecipeList from "@/components/RecipeList.vue";
import RecipeItemDetail from "@/components/RecipeItemDetail.vue";
import MakeOrder from "@/components/MakeOrder.vue";
import TheBeef from "@/components/TheBeef.vue";

import ThePayResultHeader from "@/components/ThePayResultHeader.vue";
import ThePayResult from "@/components/ThePayResult.vue";

import Main_swiper from "@/components/Main_swiper.vue";
import { recipes } from "@/models/model_item";
import { useStore } from "@/store";
import { $vfm } from "vue-final-modal";
import TheTest from "@/components/TheTest.vue";
import { toast } from "vue3-toastify";

export default defineComponent({
  components: {
    TheHeader,
    TheFooter,
    CatalogList, // eslint-disable-next-line
    TheBasket, // eslint-disable-next-line
    PickUpLocation,
    CatalogItemDetail,
    RecipeList,
    RecipeItemDetail, // eslint-disable-next-line
    MakeOrder,
    TheBeef,
    Main_swiper,
  },
  data() {
    return {
      loading: false,
      recipes,
      meatInfo: {},
    };
  },
  methods: {
    priceFormat(value: number) {
      return Intl.NumberFormat().format(value) + " ₽";
    },
    showBasket() {
      if (this.basket.items.length > 0) {
        $vfm.show({
          component: TheTest,
          bind: {
            title: "Ваш заказ",
          },
          slots: {
            content: {
              component: TheBasket,
            },
          },
        });
      } else {
        toast("Корзина пустая!", {
          position: "bottom-right",
          theme: "dark",
          progressStyle: {
            background: "green",
          },
        });
      }
    },
    showMakeOrder() {
      $vfm.show({
        component: TheTest,
        bind: {
          title: "Оформление заказа",
        },
        slots: {
          content: {
            component: MakeOrder,
          },
        },
      });
    },
    showDeliveryType() {
      $vfm.show({
        component: TheTest,
        bind: { title: "Выберите способ получения" },
        slots: {
          content: {
            component: PickUpLocation,
            on: {
              success() {
                // if($vfm.dynamicModals[$vfm.dynamicModals.length-1]){
                //   $vfm.dynamicModals[$vfm.dynamicModals.length-1].value = false;
                // }
              },
            },
          },
        },
      });
    },

    openText(event: any) {
      let text = event.target.children[1];
      let svg = event.target.children[2];
      text.classList.toggle("app__payment-and-delivery-item-text--active");
      svg.classList.toggle("app__payment-and-delivery-svg--active");
      if (
        text.classList.contains("app__payment-and-delivery-item-text--active")
      ) {
        text.style.maxHeight = text.scrollHeight + "px";
        text.style.marginBottom = 24 + "px";
      } else {
        text.style.maxHeight = "0px";
        text.style.marginBottom = "0px";
      }
    },
  },
  mounted() {
    this.loading = true;
    let html = document.querySelector("body");
    if (html) {
      html.style.overflow = "hidden";
    }
    setTimeout(() => {
      this.loading = false;
      if (html) {
        html.style.overflow = "inherit";
      }
      if (window.location.hash) {
        $vfm.show({
          component: ThePayResultHeader,
          slots: {
            content: {
              component: ThePayResult,
              bind: {
                status: window.location.hash.replace('#', '').split('=')[0],
                order: window.location.hash.replace('#', '').split('=')[1],
              },
            },
          },
        });
      }
      
    }, 2500);
  },
  setup() {
    const store = useStore();

    store.dispatch("init").then((data) => {
      let containers = document.querySelectorAll(".app__container");
      let sectionsTitle = document.querySelectorAll(
        ".main-header__list-info-item"
      );
      let containerTitle = document.querySelector(".main-header__bottom");
      let observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          let marginTop = 45;
          if (
            document.querySelector(".main-header__mobile-header--active-header")
          ) {
            marginTop = 110;
          } else {
            marginTop = 50;
          }
          if (
            entry.isIntersecting &&
            entry.intersectionRect.y < marginTop &&
            window.innerWidth < 600
          ) {
            sectionsTitle.forEach((sectionTitle) => {
              if (
                sectionTitle.getAttribute("data-section-title") ==
                entry.target.getAttribute("data-section")
              ) {
                if (
                  !entry.target.classList.contains("app__container--active")
                ) {
                  containers.forEach((container) => {
                    container.classList.remove("app__container--active");
                  });
                  entry.target.classList.add("app__container--active");

                  containerTitle?.scrollTo(
                    sectionTitle.getBoundingClientRect().left - 16,
                    0
                  );
                }
              }
            });
          }
        });
      });

      containers.forEach((container) => observer.observe(container));
    });

    store.dispatch("getBasket").then((data) => {
      console.log(data);
    });

    return {
      store,
      drawer: computed(() => store.state.drawer),
      catalog: computed(() => store.state.catalog),
      basket: computed(() => store.state.basket),
    };
  },
});
