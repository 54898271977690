
import { computed, defineComponent, PropType } from "vue";
import { IBasket } from "@/models/basket";
import { IBasketItem } from "@/models/basketItem";
import { useStore } from "@/store";
import ACounter from "@/components/controls/ACounter.vue";
import { $vfm } from "vue-final-modal";
import TheTest from "@/components/TheTest.vue";
import PickUpLocation from "@/components/PickUpLocation.vue";
import MakeOrder from "@/components/MakeOrder.vue";
import ThePersonalData from "./ThePersonalData.vue";
import PersonalData from "./PersonalData.vue";
import AuthPhone from "@/components/AuthPhone.vue";

interface UpdateItem {
  id: number;
  productId: number;
  quantity: number;
}

export default defineComponent({
  components: {
    ACounter,
  },
  data() {
    return {
      updateData: [] as Array<UpdateItem>,
      tm: 0,
      loading: false,
    };
  },
  methods: {
    nextOrder() {
      if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
        $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value = false;
      }
      if (this.isAuthorized) {
        $vfm.show({
          component: TheTest,
          bind: { title: "Выберите способ получения" },
          slots: {
            content: {
              component: PickUpLocation,
              on: {
                success() {
                  if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
                    $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value =
                      false;
                  }
                  $vfm.show({
                    component: TheTest,
                    bind: {
                      title: "Оформление заказа",
                    },
                    slots: {
                      content: {
                        component: MakeOrder,
                      },
                    },
                  });
                },
              },
            },
          },
        });
      } else {
        $vfm.show({
          component: TheTest,
          bind: { title: "" },
          slots: {
            content: {
              component: AuthPhone,
              bind: {
                success: function () {
                  $vfm.show({
                    component: TheTest,
                    bind: { title: "Выберите способ получения" },
                    slots: {
                      content: {
                        component: PickUpLocation,
                        on: {
                          success() {
                            if (
                              $vfm.dynamicModals[$vfm.dynamicModals.length - 1]
                            ) {
                              $vfm.dynamicModals[
                                $vfm.dynamicModals.length - 1
                              ].value = false;
                            }
                            $vfm.show({
                              component: TheTest,
                              bind: {
                                title: "Оформление заказа",
                              },
                              slots: {
                                content: {
                                  component: MakeOrder,
                                },
                              },
                            });
                          },
                        },
                      },
                    },
                  });
                },
              },
            },
          },
        });
      }
    },
    close() {
      this.store.commit("closeDialog", "basket");
    },
    priceFormat(value: number) {
      return Intl.NumberFormat().format(value) + " ₽";
    },
    changeQuantity(idx: number, val: number) {
      this.updateData[idx].quantity += val;
      this.updateData[idx].quantity =
        Math.round(this.updateData[idx].quantity * 100) / 100;
      if (this.updateData[idx].quantity < 0) this.updateData[idx].quantity = 0;

      if (this.tm) clearTimeout(this.tm);

      // this.loading = true;
      this.tm = setTimeout(() => {
        this.store
          .dispatch("updateBasket", { items: this.updateData })
          .finally(() => {
            // this.loading = false;
          });
      }, 600);
    },
    changeBasket() {
      if (this.tm) clearTimeout(this.tm);
      this.loading = true;
      this.tm = setTimeout(() => {
        this.store
          .dispatch("updateBasket", { items: this.updateData })
          .then(()=>{
            this.initBasket();
          })
          .finally(() => {
            this.loading = false;
          });
      }, 600);
    },
    deleteItemBasket(id) {
      this.store.dispatch("deleteItemBasket", id).finally(() => {
        this.store.dispatch("getBasket").then((data) => {
          this.loading = false;
        });
      });
    },
    showPersonalData() {
      $vfm.show({
        component: ThePersonalData,
        bind: {
          title: "Персональные данные",
        },
        slots: {
          content: {
            component: PersonalData,
          },
        },
      });
    },
    initBasket(){
      this.updateData = [];
      (this.basket.items as Array<IBasketItem>).forEach((item) => {
        this.updateData.push({
          id: item.id,
          productId: item.productId,
          quantity: item.quantity,
        });
      });
    }
  },
  mounted() {
    this.initBasket();

    //ym
    if(window['ym']){
      window['ym'](94831775,'reachGoal','korzina');
    }
  },
  watch: {
    // source(value) {
    //   this.updateData = [];
    //   (value.items as Array<IBasketItem>).forEach((item) => {
    //     this.updateData.push({
    //       id: item.id,
    //       productId: item.productId,
    //       quantity: item.quantity,
    //     });
    //   });
    // },
    basketInfo(event) {
      if (event == true) {
        document.querySelector("html")?.classList.add("html--active");
      }
      if (event == false) {
        document.querySelector("html")?.classList.remove("html--active");
      }
    },
  },
  setup() {
    const store = useStore();
    return {
      store,
      basket: computed(() => store.state.basket),
      isAuthorized: computed(() => store.state.isAuthorized),
    };
  },
});
