import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83238f04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-head" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, _mergeProps(_ctx.$attrs, {
    classes: "modal-container",
    "content-class": "modal-content"
  }), {
    default: _withCtx(({ close, params }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          class: "btn-close",
          onClick: close
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "content", {
          params: params,
          message: "hello"
        }, undefined, true)
      ])
    ]),
    _: 3
  }, 16))
}