import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37c5cf6d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-head" }
const _hoisted_2 = { class: "modal-head__title" }
const _hoisted_3 = {
  key: 0,
  class: "modal-head__right"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "modal-head__right" }
const _hoisted_6 = {
  key: 0,
  class: "loader"
}
const _hoisted_7 = { class: "modal__content" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, _mergeProps(_ctx.$attrs, {
    classes: "modal-container",
    "content-class": "modal-content"
  }), {
    default: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("input", {
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchItem($event.target))),
            class: "search__input",
            type: "text"
          }, null, 32)
        ]),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("span", {
                class: "btn-close",
                onClick: close
              }, null, 8, _hoisted_4)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("ul", {
          class: _normalizeClass(["search__list", { 'search__list--active': this.items.length > 0 }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
            return (_openBlock(), _createElementBlock("li", {
              key: idx,
              class: "search__item",
              onClick: ($event: any) => (_ctx.showDetail(item))
            }, _toDisplayString(item.name), 9, _hoisted_8))
          }), 128)),
          (_ctx.items.length == 0 && _ctx.text != '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Ничего не найдено!"))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ]),
    _: 1
  }, 16))
}