
import { useStore } from "@/store";
import { defineComponent } from "vue";
import TheDetail from "./TheDetail.vue";
import { $vfm } from "vue-final-modal";
import CatalogItemDetail from "./CatalogItemDetail.vue";
import RecipeItemDetail from "@/components/RecipeItemDetail.vue";

export default defineComponent({
  data() {
    return {
      loading: false,
      text: "",
      tm: 0,
      items: [],
    };
  },
  mounted() {
    let item = document.querySelector(".search__input") as HTMLElement
    if(item) {
      setTimeout(()=>{
        item.focus()
      },300)      
    }
  },
  methods: {
    showDetail(item) {

      console.log(item);

      if(item['itemType'] == 'product'){
        $vfm.show({
          component: TheDetail,
          bind: {
            title: "Ваш заказ",
          },
          slots: {
            content: {
              component: CatalogItemDetail,
              bind: {
                item: item,
              },
            },
          },
        });
      }
      else{
        $vfm.show({
          component: TheDetail,
          bind: {
            title: "Ваш заказ",
          },
          slots: {
            content: {
              component: RecipeItemDetail,
              bind: {
                item: item
              }
            },
          },
        });
      }

    },

    searchItem(e) {
      if (this.tm) clearTimeout(this.tm);
      this.loading = true;
      this.tm = setTimeout(() => {
        this.store
          .dispatch("getSearchItems", e.value)
          .then((data) => {
            this.items = data.items;
            this.text = e.value;
          })
          .finally(() => {
            this.loading = false;
          });
      }, 600);
    },
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
});
