
import { defineComponent } from "vue";
import TheAccount from "@/components/TheAccount.vue";
import AccountOrder from "@/components/AccountOrder.vue";
import { $vfm } from "vue-final-modal";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      defaul: "",
    },
    status: {
      type: String,
      default: "",
    },
    order: {
      type: String,
      default: "",
    },
  },

  methods: {
    openOrderHistory() {
      if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
        $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value = false;
      }
      $vfm.show({
        component: TheAccount,
        bind: { title: "История заказов", back: true },
        slots: {
          content: {
            component: AccountOrder,
          },
        },
      });
    },
  },
});
