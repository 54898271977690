import { createApp, provide } from 'vue'
import App from './App.vue'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import PrimeVue from 'primevue/config';

import { vfmPlugin} from 'vue-final-modal'

// import { createVfm } from 'vue-final-modal'
// const vfm = createVfm()
// app.use(vfm)

import { store, key } from './store'

import './assets/styles/style.scss'

const vuetify = createVuetify({
  components,
  directives,
})

import VueLazyLoad from 'vue3-lazyload';

import { vMaska } from "maska"
import Api from "@/services/api";
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

createApp(App)
    .use(vuetify)
    .use(PrimeVue)
    .use(store, key)
    .use(Vue3Toasity)
    .use(VueLazyLoad, {})
    .use(vfmPlugin({
        key: '$vfm',
        componentName: 'VueFinalModal',
        dynamicContainerName: 'ModalsContainer'
      }))
    .directive("maska", vMaska)
    .provide<Api>('api', new Api())
    .mount('#app');
