
import { defineComponent, inject, computed } from "vue";
import Api from "@/services/api";
import moment from "moment";
import { useStore } from "@/store";
import { $vfm } from "vue-final-modal";
import TheTest from "@/components/TheTest.vue";
import TheBasket from "@/components/TheBasket.vue";
import CatalogItemDetail from "@/components/CatalogItemDetail.vue";
import TheDetail from "@/components/TheDetail.vue";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      basket: [] as any,
      price: 0,
      shipments: [],
      properties: [],
    };
  },
  mounted() {
    this.api.order(this.id).then((resp) => {
      let data = resp.data;

      this.basket = data.basket;
      this.price = data.price;
      this.shipments = data.shipments;
      this.properties = data.properties;
      console.log(data.properties);
    });
  },
  methods: {
    showDetail(id) {
      const item = this.catalog.items.filter((catalogItem) => {
        if (catalogItem.offers?.length != 0 && catalogItem.offers) {
          const offerId = catalogItem.offers.some((offer) => {
            return offer.id == id;
          });

          if (offerId) {
            return catalogItem;
          }
        } else {
          return catalogItem.id == id;
        }
      });

      $vfm.show({
        component: TheDetail,
        bind: {
          title: "Ваш заказ",
        },
        slots: {
          content: {
            component: CatalogItemDetail,
            bind: {
              item: item[0],
            },
          },
        },
      });
    },

    repeatOrder() {
      this.loading = true;
      this.store.dispatch("deleteAllItemsBasket").finally(() => {
        this.store
          .dispatch("getBasket")
          .then(() => {
            let promiseList: Array<any> = [];

            for (let i = 0; i < this.basket.items.length; i++) {
              let p = this.store.dispatch("addBasket", {
                productId: this.basket.items[i].productId,
                quantity: this.basket.items[i].quantity,
              });
              promiseList.push(p);
            }

            Promise.all(promiseList).then(() => {
              $vfm.show({
                component: TheTest,
                bind: {
                  title: "Ваш заказ",
                },
                slots: {
                  content: {
                    component: TheBasket,
                  },
                },
              });
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    moment(date) {
      moment.locale("ru");
      return moment(date).format("DD MMMM");
    },

    displayInfo(item) {
      if (item.ratio != 1) {
        if (item.ratio >= 1) {
          return item.ratio + " кг ";
        }

        return item.ratio * 1000 + " г ";
      }
      return null;
    },

    addressDetail(address) {
      let string = "";
      address.forEach((item) => {
        if (item.code == "address" && item.value != "") {
          string += item.value;
        }
        if (item.code == "entrance" && item.value != "") {
          string += ", " + item.value + " подъезд";
        }
        if (item.code == "floor" && item.value != "") {
          string += ", " + item.value + " эт.";
        }
        if (item.code == "flat" && item.value != "") {
          string += ", кв. " + item.value;
        }
      });
      return string;
    },
  },
  setup() {
    const store = useStore();
    const api = inject<Api>("api", () => new Api(), true);
    return {
      store,
      api,
      catalog: computed(() => store.state.catalog),
    };
  },
});
